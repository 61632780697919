const defaults = {
  classVisible: '-visible',
  moduleSelector: '[data-cookie-banner]',
  cookieName: 'profitalCookieAcceptance',
};

export default class CookieBanner {
  constructor(settings = {}) {
    this.settings = { ...defaults, ...settings };
  }

  async init($el) {
    const $modules = [...document.querySelectorAll(this.settings.moduleSelector)];
    if ($modules.length === 0) {
      return this;
    }

    if (this.getCookie(this.settings.cookieName)) {
      this.addScript();
    } else {
      // Show the Cookie Banner
      $el.classList.add(this.settings.classVisible);

      // Bind Events
      const $button = $el.querySelector('#accept-cookies');

      $button.addEventListener('click', () => {
        // Hide Cookie Banner
        $el.classList.remove(this.settings.classVisible);

        // Set Cookie
        this.setCookie(this.settings.cookieName, true, 90);

        if (window.app.env !== 'local') {
          this.addScript();
        }
      });
    }

    return this;
  }

  setCookie(name, value, days) {
    let date;
    let expires;

    if (days) {
      date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    } else {
      expires = '';
    }
    document.cookie = `${name}=${value}${expires}; path=/`;

    return this;
  }

  getCookie(name) { // eslint-disable-line class-methods-use-this
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();

    return false;
  }

  addScript() {
    const script = document.createElement('script');
    script.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KD2CK57');`; // eslint-disable-line quotes

    document.head.appendChild(script);

    return this;
  }
}
