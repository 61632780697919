export default class LazyLoadVideoSource {
  async init($el) {
    window.addEventListener('load', () => {
      const source = $el.dataset.videoSrc;
      const video = $el.parentElement;

      $el.setAttribute('src', source);
      video.load();
    });

    return this;
  }
}
